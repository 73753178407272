import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaTrash, FaPlus } from 'react-icons/fa'; // Import the icons
import '../style/Form.css';

const apiUrl = process.env.REACT_APP_API_URL;

function EditProject() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState('upcoming');
  const [completionDate, setCompletionDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
  const [projectType, setProjectType] = useState('');
  const [image, setImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [newImage, setNewImage] = useState(null); // State to handle new image uploads

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/projects/${id}`);
        setProject(response.data);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setDate(response.data.date.split('T')[0]);
        setCategory(response.data.category);
        setCompletionDate(response.data.completedDate ? response.data.completedDate.split('T')[0] : '');
        setStartDate(response.data.startingDate ? response.data.startingDate.split('T')[0] : '');
        setExpectedCompletionDate(response.data.expectedCompletionDate ? response.data.expectedCompletionDate.split('T')[0] : '');
        setProjectType(response.data.projectType || '');
        setGalleryImages(response.data.galleryImages || []);
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };

    fetchProject();
  }, [id]);

  const handleFileChange = (e) => {
    if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'Please select a file smaller than 1 MB.',
      });
      return;
    }
    setImage(e.target.files[0]);
  };
 

  const handleNewImageChange = async (event) => {
    if (event.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'Please select a file smaller than 1 MB.',
      });
      return;
    }
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`${apiUrl}/api/projects/${id}/gallery`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const newImage = response.data.image;
        setGalleryImages((prevImages) => [...prevImages, newImage]);
        setNewImage(null); // Clear the input after upload
        Swal.fire({
          title: 'Success!',
          text: 'Image uploaded successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to upload image.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.error('Error uploading image:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('date', date);
    formData.append('category', category);
    formData.append('projectType', projectType);
    formData.append('completedDate', completionDate);
    formData.append('startingDate', startDate);
    formData.append('expectedCompletionDate', expectedCompletionDate);
    if (image) formData.append('image', image);

    try {
      await axios.put(`${apiUrl}/api/projects/edit/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: 'Success!',
        text: 'Project updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        navigate('/projects');
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update project.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.error(error);
    }
  };

  const handleDeleteImage = async (image) => {
    try {
      await axios.delete(`${apiUrl}/api/projects/${id}/gallery/${image}`);
      setGalleryImages(galleryImages.filter(img => img !== image));
      Swal.fire({
        title: 'Deleted!',
        text: 'Image has been deleted.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to delete image.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      console.error('Error deleting image:', error);
    }
  };

  if (!project) return <p>Loading...</p>;

  return (
    <>
      <style>
        {`
          .gallery-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }

          .gallery-image {
            width: 100px;
            height: auto;
            object-fit: cover;
            position: relative;
          }

          .delete-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            background: white;
            border: 1px solid black;
            border-radius: 50%;
            cursor: pointer;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .delete-icon:hover {
            background: red;
            color: white;
          }

          .upload-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            position: relative;
          }

          .upload-icon {
            font-size: 24px;
            color: green;
            cursor: pointer;
          }

          .upload-icon:hover {
            color: darkgreen;
          }

          .upload-button {
            display: none;
          }
        `}
      </style>

      <Link to="/projects" className="btn btn-secondary mb-3">Back to Projects</Link>
      <div className="form">
        <h2 className="form-header">Edit Project</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <ReactQuill
              id="description"
              theme="snow"
              className="quill-editor"
              value={description}
              onChange={setDescription}
              required
            />
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              className="form-control"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="upcoming">Upcoming</option>
              <option value="ongoing">Ongoing</option>
              <option value="completed">Completed</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="projectType">Project Type</label>
            <select
              id="projectType"
              className="form-control"
              value={projectType}
              onChange={(e) => setProjectType(e.target.value)}
              required
            >
              <option value="">Select Type</option>
              <option value="construction">Construction</option>
              <option value="welfare">Welfare</option>
              <option value="sport">Sport</option>
              <option value="education">Education</option>
              <option value="hotel industry">Hotel Industry</option>
              <option value="commercial">Commercial</option>
            </select>
          </div>

          {category === 'completed' && (
            <div className="form-group">
              <label htmlFor="completionDate">Completion Date</label>
              <input
                type="date"
                id="completionDate"
                className="form-control"
                value={completionDate}
                onChange={(e) => setCompletionDate(e.target.value)}
              />
            </div>
          )}
       {category === 'upcoming' && (
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              id="startDate"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
        )}
          {category === 'ongoing' && (
            <div className="form-group">
              <label htmlFor="expectedCompletionDate">Expected Completion Date</label>
              <input
                type="date"
                id="expectedCompletionDate"
                className="form-control"
                value={expectedCompletionDate}
                onChange={(e) => setExpectedCompletionDate(e.target.value)}
              />
            </div>
          )}

          <div className="form-group"><p style={{ color: 'red' }}>Image must be less than 5MB </p>
            <label htmlFor="image">Image</label>
            <input
              type="file"
              id="image"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>

         

        <div className="gallery-container">
          {galleryImages.map((img) => (
            <div key={img} className="gallery-image">
              <img src={`${apiUrl}/uploads/${img}`} alt={img} className="img-fluid" />
              <div className="delete-icon" onClick={() => handleDeleteImage(img)}>
                <FaTrash />
              </div>
            </div>
          ))}
        </div>

        <div className="upload-container">
          <label htmlFor="new-image" className="upload-icon">
            <FaPlus />
          </label>
          
          <input
            type="file"
            id="new-image"
            className="upload-button"
            onChange={handleNewImageChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Project</button>
        </form>
      </div>
    </>
  );
}

export default EditProject;
