import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Maintenance = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#f8f9fa' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="text-center p-4 bg-white rounded shadow">
                            
                            <h1 className="mb-3" style={{ color: '#343a40' }}>We'll be Back Soon!</h1>
                            <p className="lead" style={{ color: '#6c757d' }}>
                                Our website is currently undergoing scheduled maintenance. Thank you for your patience.
                            </p>
                            <p>
                                If you need assistance, please contact us at{' '}
                                <a href="mailto:civicavenuemall@gmail.com">support@albasitgoc.com</a>.
                            </p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Maintenance;

