import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const apiUrl = process.env.REACT_APP_API_URL;

const MessagesDashboard = () => {
  const [messages, setMessages] = useState([]);

  // Fetch all messages when the component mounts
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/messages`);
        const sortedMessages = response.data.sort((a, b) => (a.status === 'unread' ? -1 : 1));
        setMessages(sortedMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  // Handle marking a message as read
  const handleMarkAsRead = async (messageId) => {
    try {
      const response = await axios.patch(`${apiUrl}/api/messages/${messageId}/markAsRead`);
      const updatedMessages = messages.map((message) =>
        message._id === messageId ? response.data : message
      );
      const sortedMessages = updatedMessages.sort((a, b) => (a.status === 'unread' ? -1 : 1));
      setMessages(sortedMessages);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  // Handle replying to a message
  const handleReply = (email) => {
    const mailto = `mailto:${email}`;
    window.location.href = mailto;
  };

  return (
    <div className="container mt-4">
      <h3 className="mb-4">Contact Messages</h3>
      {messages.length === 0 ? (
        <p>No messages available.</p>
      ) : (
        messages.map((message) => (
          <div key={message._id} className="card mb-3 position-relative">
            <div className="card-body">
              <h5 className="card-title">{message.name}</h5>
 		<h6 className="card-subtitle mb-2 text-muted">{message.email}</h6>
              <h6 className="card-subtitle mb-2 text-muted">{message.subject}</h6>
              <p className="card-text">{message.message}</p>
              <p className="card-text"><small className="text-muted">Sent at: {new Date(message.sentAt).toLocaleString()}</small></p>
              <p className="card-text"><small className="text-primary">Category: {message.category}</small></p>
              <div className="d-flex justify-content-end align-items-center position-absolute bottom-0 end-0 p-2">
                {message.status === 'unread' && (
                  <button 
                    className="btn btn-link text-primary me-2" 
                    onClick={() => handleMarkAsRead(message._id)}
                    aria-label="Mark as Read"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                )}
                <button 
                  className="btn btn-link text-primary" 
                  onClick={() => handleReply(message.email)}
                  aria-label="Reply"
                >
                  <i className="fas fa-reply"></i>
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MessagesDashboard;
