import React, { useState, useEffect, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaFilter, FaSortUp, FaSortDown, FaEdit, FaTrash } from "react-icons/fa"; // Import icons
import { Dropdown } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../style/List.css';
import { AuthContext } from '../../Context/authContext';

const apiUrl = process.env.REACT_APP_API_URL;

function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterProjectType, setFilterProjectType] = useState('all');
  const { user } = useContext(AuthContext);
  const userRole = user ? user.role : 'guest';
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const categoryFromQuery = queryParams.get('category');
    const typeFromQuery = queryParams.get('projectType');

    if (categoryFromQuery) {
      setFilterCategory(categoryFromQuery);
    }

    if (typeFromQuery) {
      setFilterProjectType(typeFromQuery);
    }

    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/projects`);
        setProjects(response.data);
        setFilteredProjects(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProjects();
  }, [location.search]);

  useEffect(() => {
    const sortProjects = (projects) => {
      return projects.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
    };

    const applyFilter = (projects) => {
      let filtered = projects;

      if (filterCategory !== 'all') {
        filtered = filtered.filter(project => project.category === filterCategory);
      }

      if (filterProjectType !== 'all') {
        filtered = filtered.filter(project => project.projectType === filterProjectType);
      }

      return filtered;
    };

    const sortedProjects = sortProjects([...projects]);
    const filteredAndSortedProjects = applyFilter(sortedProjects);

    setFilteredProjects(filteredAndSortedProjects);
  }, [sortOrder, filterCategory, filterProjectType, projects]);

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const response = await axios.delete(`${apiUrl}/api/projects/${id}`);
              if (response.status === 200) {
                setProjects(projects.filter(project => project._id !== id));
                setFilteredProjects(filteredProjects.filter(project => project._id !== id));
              } else {
                alert('Failed to delete project');
              }
            } catch (err) {
              alert('Failed to delete project');
              console.error(err);
            }
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleSortToggle = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleFilterChange = (category) => {
    setFilterCategory(category);
  };

  const handleProjectTypeChange = (type) => {
    setFilterProjectType(type);
  };

  const handleEdit = (id) => {
    navigate(`/projects/edit/${id}`);
  };

  return (
    <div className="container">
      <div className="d-flex mb-4">
        <div className="d-flex btn-container">
          <button onClick={handleSortToggle} className="btn btn-info btn-spacing">
            {sortOrder === "asc" ? <FaSortUp className="sort-icon" /> : <FaSortDown className="sort-icon" />}
            <span className="sort-text" style={{ color: 'white' }}>Sort</span>
          </button>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-success btn-spacing">
              <FaFilter />
              <span className="sort-text" style={{ color: 'white' }}>Filter</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleFilterChange('all')}>All Categories</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange('upcoming')}>Upcoming</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange('ongoing')}>Ongoing</Dropdown.Item>
              <Dropdown.Item onClick={() => handleFilterChange('completed')}>Completed</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          
          {['admin', 'editor'].includes(userRole) && (
            <Link to="/add-projects" className="btn btn-primary btn-spacing">
              Add
            </Link>
          )}
        </div>
      </div>

      {error && <p className="text-danger">Error: {error}</p>}
      <div className="row">
        {filteredProjects.map((project) => (
          <div key={project._id} className="col-md-4 mb-4">
            <div className="card">
              <Link to={`/projects/${project._id}`} className="text-decoration-none">
                <img
                  src={`${apiUrl}/uploads/${project.mainImage}`}
                  className="card-img-top"
                  alt={project.title}
                  style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                />
                <div className="card-body">
                  <h5 className="card-title" style={{ height: "30px", overflow: "hidden", width: "100%" }}>
                    {project.title}
                  </h5>
                  <p className="card-footer">
                    <b>Category:</b> {project.category}
                    <br />
                    <b>Type:</b> {project.projectType}
                    <br />
                    <b>Date:</b> {new Date(project.date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </Link>
              {['admin', 'editor'].includes(userRole) && (
                <div className="d-flex justify-content-center p-2">
                  <button onClick={() => handleEdit(project._id)} className="btn btn-cutsom m-1">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDelete(project._id)} className="btn btn-transparent m-1">
                    <FaTrash />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectList;
