import React from 'react';
import { FaFacebookF,  FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import '../style/Footer.css'
import NewsletterForm from './NewsletterForm';
const Footer = () => {
  return (
    <footer className="text-white bg-dark">
      <div className="container py-4">
        <div className="row text-center text-md-left">
          <div className="col-md-4 mb-3 mb-md-0">
            <h5 className="mb-3">Al Basit</h5>
            <p>
              Al Basit Group of Companies is a multi-disciplinary organization involved in various industries with a strong regional & Global Presence.
            </p>
            <div className="social-icons mt-3">
              <a href="https://www.facebook.com/profile.php?id=100074705643184&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaFacebookF />
              </a>
              <a href="https://youtube.com/@albasitgoc" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaYoutube />
              </a>
              <a href="https://www.instagram.com/albasitgoc " target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h5 className="mb-3">Get In Touch</h5>
            <p>Civic Center, Bahria Town (Phase - 4) Rawalpindi</p>
            <p>0330 0000422</p>
            <p><a href="mailto:info@albasitgoc.com" className="text-white">info@albasitgoc.com</a></p>
          </div>
          <div className="col-md-4">

            <h5 className="mb-3">Location</h5>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.2221939933!2d73.04719931521067!3d33.51859628075579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc1039393eeb7%3A0x34bdab2dcedb25ae!2sBahria%20Town%20Phase%204%2C%20Islamabad%2C%20Rawalpindi%2C%20Islamabad%20Capital%20Territory!5e0!3m2!1sen!2spk!4v1605708401394!5m2!1sen!2spk" 
              width="90%" 
              height="200" 
              style={{border: 0}} 
              allowFullScreen="" 
              loading="lazy"
              title="Location"
            />
          </div>
        </div>
      </div>
      <NewsletterForm/>
      <div className="container copyrigth py-3">
        <small>&copy; 2024 All Rights Reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
