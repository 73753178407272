import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/contactform.css';
import Header from '../Components/User/Header';
import Footer from '../Components/User/Footer';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
function Invest() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      name,
      email,
      subject,
      message,
      category,
    };
  
    try {
      await axios.post(`${apiUrl}/api/messages`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      Swal.fire({
        title: 'Success!',
        text: 'Message sent successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/');
      });
  
      // Clear form fields
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setCategory('');
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to send message.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error(error);
    }
  };
  

       

        
  return (
    <div className="">
      <Header />
      <h2 className="mb-4 text-center p-4">Contact Us</h2>
      <section className="contact-section">
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="category">Interested To Invest In</label>
              <select
                id="category"
                className="form-control"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <option value="">Select an option</option>
                <option value="Shop">Shop</option>
                <option value="Food-Court">Food Court</option>
                <option value="Offices">Offices</option>
                <option value="2-bed-apartment">2 bed apartment</option>
                <option value="3-bed-apartment">3 bed apartment</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Send Message</button>
          </form>
        </div>
        <div className="contact-info">
          <h2>CALL US AT (24/7) TOLL FREE</h2>
          <p>0800 00100</p>
          <p>If calling from outside Pakistan, please contact us below:</p>
          <div className="office-info">
            <h3>Dubai</h3>
            <p>BTMS Consultancy DMCC</p>
            <p>Advertising & Marketing Services</p>
            <p>Cluster M, Jumeirah Lake Towers, Dubai, UAE</p>
            <p>P. +971 4 429 2700</p>
            <p>Email: salesdubai@bahriatown.com</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Invest