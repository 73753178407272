import React, { useEffect, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { AuthContext } from "./Context/authContext";
import Sidebar from "./Components/Admin/Sidebar";
import Topbar from "./Components/Admin/TopBar";
import Bloglist from "./Components/Common/BlogList";
import BlogForm from "./Components/Admin/BlogForm";
import BlogDetails from "./Components/Common/BlogDetails";
import AdminEmailForm from "./Components/Admin/AdminEmailForm";
import ProjectList from "./Components/Common/ProjectList";
import EditBlog from "./Components/Admin/EditBlog";
import ProjectDetails from "./Components/Common/ProjectDetails";
import ProjectForm from "./Components/Admin/ProjectForm";
import PerformanceDashboard from "./Components/Admin/PerformanceDashboard";
import Loginform from "./Components/Admin/Loginform";
import Registration from "./Components/Admin/Registration";
import Settings from "./Components/Admin/settings";
import EditProject from "./Components/Admin/EditProject";
import MessagesDashboard from "./Components/Admin/MessagesDashboard";
import Comments from "./Components/Admin/Comments";
import EditProfile from "./Components/Admin/EditProfile";
import ContactusForm from './Components/User/ContactusFrom'
import HomePage from './Pages/HomePage'
import Albasit from './Components/User/Albasit'
import ProjectListUser from './Components/User/ProjectListUser'
import RecentArticles from "./Components/User/RecentArticles";
import Invest from "./Pages/Invest"
import Blog from "./Pages/Blog";
import RegisterForm from "./Components/Admin/Registration";
import "./App.css";
import Project from "./Pages/Projects";
import AboutUs from "./Pages/AboutUs";
import Csr from "./Pages/Csr";
import UserList from "./Components/Admin/Userlist";
import VideoGallery from './Pages/VideoGallery'
import UserProfile from "./Components/Admin/UserProfile";
import ChangePassword from "./Components/Admin/ChangePassworrd";
import Maintainance from "./Pages/maintainance.js"

const ProtectedRoute = ({ element, roles }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
        return <Navigate to="/" />;
    }

    if (roles && !roles.includes(user.role)) {
        return <Navigate to="/" />; // Redirect unauthorized users to home or a specific page
    }

    return element;
};

const App = () => {
   
    const { user } = useContext(AuthContext);


    return (
        <>
     
                {user ? (
                    // If user is authenticated
                    <> 
                    <div className="container-fluid main-container">
            <div className="row">
                        <Sidebar key={user?.profileImage} />
                        <div className="col-md-9 col-lg-10 col-sm-12 content mx-auto">
                            <Topbar />
                            <Routes>
                                {/* Admin Dashboard and other protected routes */}
                                <Route path="/" element={<ProtectedRoute element={<PerformanceDashboard />} roles={['admin','editor']} />} />
                                <Route path="/blogs" element={<ProtectedRoute element={<Bloglist />} roles={['admin', 'editor']} />} /> 
                                <Route path="/add-blog" element={<ProtectedRoute element={<BlogForm />} roles={['admin','editor']} />} />
                                <Route path="/projects" element={<ProtectedRoute element={<ProjectList />} roles={['admin', 'editor']} />} />
                                <Route path="/projects/:id" element={<ProtectedRoute element={<ProjectDetails />} roles={['admin', 'editor']} />} />
                                <Route path="/blogs/:id" element={<ProtectedRoute element={<BlogDetails />} roles={['admin', 'editor']} />} />
                                <Route path="/add-projects" element={<ProtectedRoute element={<ProjectForm />} roles={['admin','editor']} />} />
                                <Route path="/projects/edit/:id" element={<ProtectedRoute element={<EditProject />} roles={['admin','editor']} />} />
                                <Route path="/blogs/edit/:id" element={<ProtectedRoute element={<EditBlog />} roles={['admin','editor']} />} />
                                <Route path="/newsletter" element={<ProtectedRoute element={<AdminEmailForm />} roles={['admin','editor']} />} />
                                <Route path="/messages" element={<ProtectedRoute element={<MessagesDashboard />} roles={['admin']} />} />
                                <Route path="/comments" element={<ProtectedRoute element={<Comments />} roles={['admin']} />} />
                                <Route path="/settings" element={<ProtectedRoute element={<Settings />} roles={['admin','editor']} />} />
                                <Route path="/settings/edit-profile" element={<ProtectedRoute element={<EditProfile />} roles={['admin','editor']} />} />
                                <Route path="/settings/profile" element={<ProtectedRoute element={<UserProfile />} roles={['admin','editor']} />} />
                                <Route path="/settings/manage-roles" element={<ProtectedRoute element={<UserList />} roles={['admin']} />} />
   <Route path="/settings/change-password" element={<ProtectedRoute element={<ChangePassword />} roles={['admin','editor']} />} />
                                <Route path="/register" element={<ProtectedRoute element={<RegisterForm />} roles={['admin']} />} />
               <Route path="/dashboard" element={<ProtectedRoute element={<PerformanceDashboard/>} roles={['admin','editor']} />}/>
                               
                            </Routes>
                        </div>
                        </div>
                        </div>
                    </>
                ) : (
                    // If user is not authenticated
                    <div className="col-md-12 content mx-auto">
                        <Routes>
                            {/* Public routes */}
                            <Route path="/login" element={<Loginform />} />
                           
                            <Route path="/" element={<Maintainance />} />
                            <Route path="/albasit" element={<Albasit />} />
                            <Route path="/projects" element={<Project />} />
                            <Route path="/register" element={<Registration />} />
                            
                            <Route path="/project" element={<ProjectListUser />}  />
                            <Route path="/blog" element={<RecentArticles />}  />
                            <Route path="/contactus" element={<ContactusForm />}  />
                            <Route path="/projects/:id" element ={<ProjectDetails />} />
                            <Route path="/blogs/:id" element={<BlogDetails  />} />
                             <Route path="/blogs" element={<Blog />} />
                            <Route path="/Aboutus" element={<AboutUs/>}/>
                            <Route path="/Csr" element={<Csr/>}/>
                            <Route path="/VideoGallery" element={<VideoGallery/>}/>
                             <Route path="/invest" element={<Invest />} />
                        </Routes>
                    </div>
                )}
        
            </>
    );
};

export default App;
